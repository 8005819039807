import { Ref } from 'vue'

import { PublicImage } from '@/service/__generated-api'

type GeneralPageSEOData = {
  site_title?: string
  meta_description?: string
  meta_keywords?: string
  og_title?: string
  og_description?: string
  og_image?: string | PublicImage
}

export default function usePrepareSEOMeta(
  page: Ref<GeneralPageSEOData | null>
) {
  const meta = []

  if (page.value?.site_title) {
    meta.push({
      key: 'title',
      name: 'title',
      content: page.value.site_title
    })
  }

  if (page.value?.meta_description) {
    meta.push({
      key: 'description',
      name: 'description',
      content: page.value.meta_description
    })
  }

  if (page.value?.meta_keywords) {
    meta.push({
      key: 'keywords',
      name: 'keywords',
      content: page.value.meta_keywords
    })
  }

  if (page.value?.og_title) {
    meta.push({
      key: 'og:title',
      property: 'og:title',
      content: page.value.og_title
    })
  }

  if (page.value?.og_description) {
    meta.push({
      key: 'og:description',
      property: 'og:description',
      content: page.value.og_description
    })
  }

  if (page.value?.og_image) {
    meta.push({
      key: 'og:image',
      property: 'og:image',
      content:
        typeof page.value.og_image === 'string'
          ? page.value.og_image
          : page.value.og_image?.file
    })
  }

  return meta
}
