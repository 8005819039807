<template>
  <Component
    :is="getWidget(widget)"
    v-for="(widget, index) in widgets"
    :id="`widget-${widget.id}`"
    :key="widget.id"
    v-viewport-animated="{
      mode: 'fade-translate'
    }"
    class="widget-margins"
    :class="[
      `widget-mt-${widget.margin_top ?? 'none'}`,
      `widget-mb-${widget.margin_bottom ?? 'none'}`
    ]"
    :widget="widget"
    :is-first="index === 0 && !virtualFirstWidget"
    :lazy-image="index !== 0 || virtualFirstWidget"
  />
</template>

<script setup lang="ts">
import { Component as ConcreteComponent } from 'vue'

import { PublicWidget, ResourcetypeEnum } from '@/service/__generated-api'

defineProps<{ widgets: PublicWidget[]; virtualFirstWidget?: boolean }>()

const widgetsToSkipLazyHydration: ResourcetypeEnum[] = [
  'LatestNewsWidget',
  'ArtistsListWidget',
  'LineUpWidget'
]

const getWidget = (widget: PublicWidget) => {
  const Component = resolveComponent(
    `LazyWidgets${widget.resourcetype}`
  ) as ConcreteComponent
  return widgetsToSkipLazyHydration.includes(
    widget.resourcetype as ResourcetypeEnum
  )
    ? Component
    : hydrateWhenVisible(Component)
}
</script>
